// icons
import { MdHomeFilled as HomeIcon } from "react-icons/md";
import { IoChatboxEllipsesOutline as ChatIcon } from "react-icons/io5";

export const sideMenuData = [
  {
    text: "Home",
    icon: <HomeIcon />,
    route: "/",
  },
  // {
  //   text: "Chat",
  //   icon: <ChatIcon />,
  //   route: "/chat",
  // },
  // {
  //   text: "",
  //   icon: < />,
  // },
];
